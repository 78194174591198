import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Routes } from '../constants';

import { BROADCAST_CHANNEL_NAME, PATH_KEY } from '@/constants/authConstants';
import {
	SessionBroadcastEvents,
	SessionBroadcastMessage,
} from '@/context/types';

const broadcastChannel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);

export const useBroadcastChannel = () => {
	const navigate = useNavigate();

	useEffect(() => {
		const handleSessionSharing = (event: MessageEvent) => {
			if (event.data.type === SessionBroadcastEvents.requestSession) {
				if (sessionStorage.length && sessionStorage.getItem('auth')) {
					const message: SessionBroadcastMessage = {
						type: SessionBroadcastEvents.sendSession,
						data: JSON.stringify(sessionStorage),
					};

					broadcastChannel.postMessage(message);
				}
			}
		};

		broadcastChannel.addEventListener('message', handleSessionSharing);

		return () =>
			broadcastChannel.removeEventListener('message', handleSessionSharing);
	}, []);

	useEffect(() => {
		if (!sessionStorage.getItem('auth')) {
			broadcastChannel.postMessage({
				type: SessionBroadcastEvents.requestSession,
			});
		}

		const handleGetSession = (event: MessageEvent) => {
			const message: SessionBroadcastMessage = event.data;

			if (message.type === SessionBroadcastEvents.sendSession) {
				if (sessionStorage.getItem('auth')) {
					return;
				}

				const storageInfo = JSON.parse(message.data as string);
				Object.keys(storageInfo || []).forEach((key) =>
					sessionStorage.setItem(key, storageInfo[key])
				);

				const path = sessionStorage.getItem(PATH_KEY) as string;

				navigate(path || Routes.home);

				window.location.reload();
			}
		};

		broadcastChannel.addEventListener('message', handleGetSession, {
			once: true,
		});
	}, []);
};
